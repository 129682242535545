:root {
  --bg-color: #f5f5f5;
  --bg-white: #fff;
  --bg-black: #000;
  --green-text: #00b894;
}
* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  background-color: var(--bg-color);
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
}

.form img {
  max-width: 150px;
  width: 100%;
}

.header {
  font-family: "Courier New", Courier, monospace;
}

.header-title {
  font-size: 1.5rem;
  font-weight: 500;
}

.header,
.form-group {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  color: var(--bg-black);
}

.form {
  max-width: 400px;
  padding: 25px;
  background-color: var(--bg-white);
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 100%;
}

.form-group {
  margin: 20px auto;
  padding: 10px 15px;
  padding-bottom: 13px;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
  font-weight: 500;
  color: var(--bg-black);
  width: 100%;
}

.form-group input {
  width: 100%;
  padding: 15px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 400;
  color: var(--bg-black);
  border: 1px solid #ddd;
  outline: 0;
}

.btn {
  display: inline-block;
  padding: 0.6rem 2rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--bg-black);
  cursor: pointer;
  background-color: var(--bg-white);
  border: 2px solid #000;
  transition: all 0.3s ease;
}

.btn:hover {
  background-color: var(--bg-black);
  color: var(--bg-white);
}

.swal2-title {
  font-size: 1rem !important;
  font-weight: 500;
}

.swal2-container.swal2-center > .swal2-popup {
  padding: 20px;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.info {
  font-size: 0.9rem;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  background-color: var(--bg-white);
  color: var(--green-text);
}
